import React, {ReactNode} from "react"
import {graphql, Link, useStaticQuery} from "gatsby"
import {Button, Card, Col, Row} from "react-bootstrap"
import { GatsbyImage } from "gatsby-plugin-image";
import {Bouwtype} from "gatsby-theme-nieuwbouw/src/graphqlTypes";
import Specificaties from "gatsby-theme-nieuwbouw/src/components/type/Specificaties";
import Badges from "gatsby-theme-nieuwbouw/src/components/nummer/Badges";
import Prijs from "gatsby-theme-nieuwbouw/src/components/Prijs";

interface IBouwtype {
    bouwtype: Bouwtype
    children: ReactNode
}

const BouwtypeCard = ({ bouwtype, children }: IBouwtype) => {
    return (
        <Card className="h-100 border-0 bg-primary">
            {bouwtype.hoofdfoto &&
            <GatsbyImage
                alt={"artist impression"}
                image={bouwtype.hoofdfoto.localFile.childImageSharp.gatsbyImageData}
                className="card-img-top" />
            }
            <Card.Body>
                <Card.Title as="h2" className="mt-3">
                    Woningtype {bouwtype.algemeen.omschrijving}
                </Card.Title>
                {children}
            </Card.Body>
        </Card>
    );
}

interface BouwtypeCardHomeProps {
    bouwtype: Bouwtype
}
const BouwtypeCardHome = ({bouwtype}: BouwtypeCardHomeProps) => {
    return (
        <>
            <div className={'my-3'}>
                {bouwtype.algemeen.koopaanneemsomVanaf &&
                <>
                    V.A. <Prijs prijs={bouwtype.algemeen.koopaanneemsomVanaf}/>
                </>
                }
            </div>
            <Button variant={'dark'} className={'text-primary'} as={Link} to={`/woningen/type-${bouwtype.fields.slug}`}>
                Bekijk woning
            </Button>
        </>
    )
}

const BouwtypeCardWoningen = ({bouwtype}: BouwtypeCardHomeProps) => {
    return (
        <>
            <div className={'my-3'}>
                <section>
                    <header>
                        <h6>
                            Bouwnummers:
                        </h6>
                    </header>
                    <Badges bouwnummers={bouwtype.bouwnummers} />
                </section>
                <Specificaties bouwtype={bouwtype}/>
                {bouwtype.algemeen.koopaanneemsomVanaf &&
                   <>
                      V.A. <Prijs prijs={bouwtype.algemeen.koopaanneemsomVanaf}/>
                   </>
                }
            </div>
            <Button variant={'dark'} className={'text-primary'} as={Link} to={`/woningen/type-${bouwtype.fields.slug}`}>
                Bekijk specificaties
            </Button>
        </>
    )
}

interface Props {
    isHome: boolean
}
export const BouwtypeList = ({isHome}: Props) => {
    const data = useStaticQuery(graphql`
        fragment BouwtypeCard on Bouwtype {
            ...BouwtypeData
            algemeen {
                omschrijving
                woonhuistype
            }
            fields {
                slug
            }
            hoofdfoto {
                ...Image
            }
        }
        query {
            allBouwtype(sort: {fields: algemeen___omschrijving}) {
                nodes {
                    id
                    ...BouwtypeCard
                    bouwnummers {
                        id
                        ...BouwnummerModal
                    }
                    diversen {
                        objectcode
                    }
                }
            }
        }`)
    return (
        <Row as="section">
            {data.allBouwtype.nodes.map((bouwtype: Bouwtype) =>
                <Col xs={12} md={6} className="my-5" key={bouwtype.id}>
                    <BouwtypeCard bouwtype={bouwtype}>
                        {isHome ?
                            <BouwtypeCardHome bouwtype={bouwtype}/>
                            :
                            <BouwtypeCardWoningen bouwtype={bouwtype}/>
                        }
                    </BouwtypeCard>
                    <hr className={'d-block d-md-none'}/>
                </Col>
            )}
        </Row>
    )
}