import React from "react"

interface Props {
  data: any,
  columns: any[]
}

export default ({ data, columns }: Props) => {
  return (
    <>
      {columns.map(column => (
          <div className={'my-2'}>
            <span className="data-header">{column.name}: </span>
            <span className={"font-weight-bold"}>{column.format(data)}</span>
          </div>
      )
      )}
    </>
  )
}