import React, {ReactNode} from "react"
import {graphql, Link} from "gatsby"
import {Button, Card, Col, Container, Row} from "react-bootstrap";
import {Header} from "../components/Header";
import {IPage} from "../types";
import Page from "gatsby-theme-nieuwbouw/src/components/Page";
import {GatsbyImage} from "gatsby-plugin-image";
import {FaArrowRight} from 'react-icons/fa';
import Prijs from "gatsby-theme-nieuwbouw/src/components/Prijs";
import {Project} from "gatsby-theme-nieuwbouw/src/graphqlTypes";
import {BouwtypeList} from "../components/Bouwtype";

interface BouwtypeProps {
    aantalWoningen: number
    lagen: "Drielaags" | "Tweelaags"
    naam: string
    vanafPrijs: number
    children: ReactNode
}

interface Props {
    data: PageQueryData
}
export default ({ data }: Props) => {
    const interieur = data.project.fotos && data.project.fotos[data.project.fotos.length - 1]

    const Bouwtype = ({aantalWoningen, lagen, naam, vanafPrijs, children}: BouwtypeProps) => {
        return (
            <Card className={'border-0'}>
                {children}
                <Card.Body className={'bg-primary'}>
                    <Card.Title as="h4" className="mt-3">
                        {aantalWoningen} kadewoningen<br />
                        {lagen}
                    </Card.Title>
                    <Card.Title as="h2" className="mt-3">
                        {naam}
                    </Card.Title>
                    V.A. <Prijs prijs={vanafPrijs}/>
                </Card.Body>
            </Card>
        )
    }
    return (
        <Page pageTitle={data.markdownRemark.frontmatter.title} pageDescription={data.markdownRemark.frontmatter.description}>
            <Header
                image={data.project.hoofdfoto?.localFile?.childImageSharp?.gatsbyImageData}
            >
                <Container
                    className={"position-absolute d-none d-md-block text-white text-center"}
                    style={{
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                    }}
                >
                    <div style={{
                        textShadow: '0px 0px 5px #000000B2',
                        letterSpacing: '1.18px'
                    }}>
                        <p style={{
                            fontSize: '1.625rem'
                        }}>
                            Kade 23 - Assen
                        </p>
                        <h1>
                            Mijn plek, mijn start
                        </h1>
                    </div>
                    <Button
                        as={Link}
                        to={'/contact'}
                        disabled
                        color={'error'}>
                        Alle woningen zijn verkocht
                    </Button>
                </Container>
            </Header>
            <div className={'d-md-none py-5 bg-primary'}>
                <Container>
                    <p style={{
                        fontSize: '1.625rem'
                    }}>
                        Kade 23 - Assen
                    </p>
                    <span className={'h2'}>
                        Mijn plek, mijn start
                    </span>
                    <Button
                        as={Link}
                        to={'/contact'}
                        variant={'dark'}>Inschrijving gesloten</Button>
                </Container>
            </div>
            <Container>
                <Row as={'section'} className={'my-5 mb-md-0'}>
                    <Col md={6} className={'my-5'}>
                        <div
                            className={'content content-intro content-left'}
                        >
                            <p style={{
                                fontSize: '1.125rem'
                            }}>
                                23 woningen Kloosterveen - Assen
                            </p>
                            <h2>
                                Hier wil ik <span className={'bg-primary'}>wonen</span>
                            </h2>
                            <p>
                                In Kloosterveen, de nieuwste wijk van Assen, wordt woonproject Kade 23 gerealiseerd. Zoals de naam al zegt, bestaat dit plan uit 23 woningen, fraai gelegen aan de Oostergracht. at wordt dit straks een fijne plek om te wonen met je partner en met het gezin.
                            </p>
                            <Link to={'/situatie'} className={'text-dark'}>Lees meer <FaArrowRight /></Link>
                        </div>
                    </Col>
                    <Col className={'my-5'}>
                        <div className={'position-relative'}>
                            {interieur &&
                            <GatsbyImage
                                alt={"interieur"}
                                image={interieur.localFile.childImageSharp.gatsbyImageData} />
                            }
                            <div
                                className={'position-absolute bg-primary p-2'}
                                style={{
                                    bottom: '20%',
                                    right: '-20px'
                                }}
                            >
                                <span>mijn plek,<br />mijn stad</span>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            <section className={'bg-primary'}>
                <Container fluid="xl">
                    <BouwtypeList isHome={true} />
                </Container>
            </section>
        </Page>
    );
}

interface PageQueryData {
    markdownRemark: IPage
    project: Project
}

export const pageQuery = graphql`
    fragment Page on MarkdownRemark {
        html
        frontmatter {
            slug
            title
            description
        }
    }
    query {
        markdownRemark(frontmatter: {slug: {eq: "home"}}) {
            ...Page
        }
        project {
            hoofdfoto {
                localFile {
                    childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH)
                    }
                }
            }
            fotos {
                localFile {
                    childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH)
                    }
                }
            }
        }
    }
`

