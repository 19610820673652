import React from "react"
import {graphql} from "gatsby"
import Range from 'gatsby-theme-nieuwbouw/src/components/Range'
import {Bouwtype} from "gatsby-theme-nieuwbouw/src/graphqlTypes";

export const columns = [
  {
    name: 'Woonoppervlak',
    format: (item: Bouwtype) => <span className="text-nowrap"><Range from={item.algemeen.woonoppervlakteVanaf} until={item.algemeen.woonoppervlakteTot} />m<sup>2</sup></span>,
  },
  {
    name: 'Perceel',
    format: (item: Bouwtype) => <span><Range from={item.algemeen.perceeloppervlakteVanaf} until={item.algemeen.perceeloppervlakteTot} />m<sup>2</sup></span>,
  },
];

export const query = graphql`
  fragment BouwtypeData on Bouwtype {
    algemeen {
      perceeloppervlakteTot
      perceeloppervlakteVanaf
      woonoppervlakteTot
      woonoppervlakteVanaf
      koopaanneemsomVanaf
      koopaanneemsomTot
    }
    bouwnummers {
      id
      ...BouwnummerModal
    }
  }
`